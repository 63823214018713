//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardText, BCardHeader, BCardBody, BCollapse, BInputGroup, BFormInput, BForm } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';
import stringSimilarity from 'string-similarity';

export default {
  name: "Instructores",
  layout: "default",
  components: { BCard, BCardText, BCardHeader, BCardBody, BCollapse, BInputGroup, BFormInput, BForm, Icon },
  head() {
    return {
      title: 'Preguntas Frecuentes',
      meta: [
        { hid: 'description', name: 'description', content: '¿Cómo funcionan los cursos en Abstracta Academy? ¿Todos los curso son relacionados a testing de software? Respondemos tus preguntas aquí.' },
        { hid: 'keywords', name: 'keywords', content: 'abstracta academy preguntas frecuentes' },
        { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
      ]
    }
  },
  data() {
    return {
      searchValue: "",
      faqList: [
        {
          question: "¿Cómo funcionan los cursos?",
          response: `Todos nuestros cursos son 100% virtuales, el material está disponible en la plataforma y avanzas a tu ritmo según los plazos especificados para cada curso.
            <br/><br/>
            Un instructor/a acompañará tu proceso de aprendizaje.`
        },
        {
          question: "¿Cuándo puedo comenzar a realizar el curso?",
          response: `Una vez que concretas la compra tienes todo el material en la plataforma y lo vas completando a tu ritmo. No hay una fecha establecida de inicio. Sí hay ciertos plazos que una vez que inicias debes cumplir. Estos plazos están especificados en el detalle del curso en el catálogo y también te los recordamos en la primera lección.`
        },
        {
          question: "¿Cómo hago para comprar un curso?",
          response: `A través del botón quiero saber más que figura en nuestra página o en contáctanos nos envías un mensaje y te indicaremos los pasos a seguir.`
        },
        {
          question: "¿Los cursos cuentan con clases en vivo?",
          response: `No contamos con clases sincrónicas. Los contenidos y materiales están disponibles en plataforma las 24hs, tenemos diferentes canales de comunicación con instructores  (mensajería instantánea a través de Slack, emails, mensajes directos por plataforma) para despejar dudas y que cada estudiante pueda avanzar en su aprendizaje sin mayores inconvenientes. En el caso de las formaciones a medida o proyectos especiales sí está la opción de clases en vivo.`
        },
        {
          question: "¿Cómo se aprueban los cursos? ",
          response: `Cada curso cuenta con diferentes instancias de evaluación: cuestionarios, tareas entregables, etc. Para obtener tu certificado debes haber entregado y aprobado con un promedio mínimo de 75/100.`
        },
        {
          question: "¿Qué métodos de pagos se aceptan?",
          response: `Trabajamos con PayPal y Mercado Pago. Si decides hacer el pago a través de PayPal deberás tener una tarjeta que te habilite a concretar la compra y saldo disponible.
            <br/><br/>
            Desde Uruguay tienes la opción de hacerlo a través de Mercado Pago y pagar el curso que elijas hasta en 12 cuotas sin interés.
            <br/><br/>
            Contáctanos que te indicaremos los pasos a seguir.`
        },
        {
          question: "¿Cómo accedo a la plataforma?",
          response: `Para acceder a la plataforma solo tienes que ingresar a <a href="https://web.academy.abstracta.us/" target="_blank">web.academy.abstracta.us</a> y registrarte.`
        },
        {
          question: "Finalicé el curso ¿Y ahora?",
          response: `Si finalizaste tu curso de manera satisfactoria recibirás el certificado expedido por Abstracta Academy. Esta certificación tiene el respaldo de Abstracta (www.abstracta.us) empresa referente en testing y reconocida internacionalmente. Desde la plataforma podrás descargar tu certificado y si quieres compartirlo directamente desde allí en LinkedIn también podrás hacerlo.
          <br/><br/>
          A nuestros estudiantes les damos prioridad cuando Abstracta recluta nuevos integrantes para el equipo, así que una vez que termines el curso puedes hacernos llegar tu cv que lo tendremos en cuenta en futuros procesos de selección.`
        },
        {
          question: "¿Cuáles son los conocimientos previos que debo tener para realizar un curso?",
          response: `Dependiendo del curso en el que desees inscribirte, contamos con una clasificación de conocimientos esenciales que debes tener para desempeñarte con éxito, adquirir los nuevos conocimientos y desarrollar las habilidades. Toda esta información está detallada en la página de cada curso. Si tienes dudas sobre esto contactanos.`
        },
        {
          question: "¿Cuánto tiempo tengo para completar los cursos?",
          response: `Esto depende. El plazo de vigencia de acceso a los contenidos de los cursos está especificado en la página de cada uno. `
        },
        {
          question: "Una vez finalizado y aprobado un curso, ¿puedo continuar accediendo a los contenidos de forma indeterminada?",
          response: `Como te mencionamos anteriormente cada curso tiene un plazo de vigencia especificado.`
        },
        {
          question: "¿Con qué curso debería empezar si no tengo conocimientos previos relacionados al área de la tecnología?",
          response: `Si te interesa dar tus primeros pasos en el área de tecnología te recomendamos el curso Software Tester 2.1 Es un curso que que no requiere conocimientos previos y es introductorio al mundo del testing. En la página del curso podrás encontrar todos los detalles.`
        },
        {
          question: "Para comenzar el curso de Software Tester 2.1, ¿tengo que comprar el paquete de los cuatro módulos juntos o puedo ir comprando de a uno?",
          response: `Puedes comprar el paquete o puedes ir comprando de a uno. El certificado lo obtendrás luego que tengas los 4 módulos aprobados.`
        },
        {
          question: "¿Qué áreas de testing abarca la propuesta de cursos que tiene Academy actualmente?",
          response: `En nuestra propuesta académica encontrarás cursos relacionados a testing funcional, automatización, performance y otras áreas de desarrollo profesional.`
        },
        {
          question: "Si actualmente soy estudiante de Abstracta Academy o egresé ¿tengo algún descuento si quiero comprar otros cursos?",
          response: `Exactamente. En Abstracta Academy creemos en el poder transformador de la educación y queremos ser parte de tu camino hacia el éxito. ¡Cuando aprendes con nosotros estás en buena compañía!<br /><br />Sabemos que la educación continua es clave para el crecimiento personal y profesional, y como muestra de nuestro compromiso con la formación en testing de software, con tu desarrollo y para agradecerte por ser parte de nuestra comunidad, ¡podrás disfrutar de un 15% off en tu próximo curso!<br /><br />Queremos seguir apoyándote en tu camino hacia el éxito y ayudarte a alcanzar tus metas a través de esta oportunidad exclusiva que nos permita continuar creciendo juntos en el camino del conocimiento.`
        },
        {
          question: "¿Cómo funciona el programa de referidos de Abstracta Acadmy?",
          response: `Nuestro programa de referidos funciona así:<br />Estudiantes o egresados pueden invitar a amigos a unirse a nuestros cursos y contarles sobre la experiencia en Abstracta Academy.<br /><br />Cuando sus referidos se inscriban y mencionen su nombre como la persona que los recomendó recibirán un 15% de descuento.<br /><br />Para acceder a este beneficio envía un correo a <a href="mailto:hello@abstracta.academy" target="_blank">hello@abstracta.academy</a>, nos pondremos en contacto a la brevedad.`
        },
        {
          question: "¿Que es un váucher de INEFOP y cómo solicitarlo?",
          response: `El váucher es un instrumento excepcional que INEFOP entrega a trabajadores/as en seguro de desempleo o en actividad en sectores en crisis como resultado de una entrevista de orientación y de acuerdo con el perfil y el proyecto educativo laboral de la persona usuaria. Se usa excepcionalmente cuando no existe propuesta de capacitación regular de INEFOP que se adapte al perfil de la persona usuaria. 
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">¿Quiénes pueden acceder a este váucher? </h6>
          Para acceder a este subsidio, las personas deben cumplir con ciertos criterios establecidos por INEFOP. Esto incluye estar desempleado/a y haber estado inscrito en el Banco de Previsión Social (BPS) durante un período específico de tiempo. Asegúrate de estar al día con el BPS y de tener toda la documentación necesaria.
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">¿Cuál es el monto del váucher?</h6>
          Desde el 21 de enero de 2021 el valor del váucher financiado por INEFOP es de 16.000 pesos uruguayos. Este monto máximo no excluye la realización de cursos de capacitación cuyo valor sea mayor. 
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">¿Cuál es el procedimiento que tiene que realizar la persona para solicitar un váucher?</h6>
          Contactános a <a href="mailto:hello@abstracta.academy" target="_blank">hello@abstracta.academy</a>, que te contamos todos los detalles.
          `
        },
        {
          question: "¿Puedo solicitar un reembolso del costo del curso?",
          response: `Hay varios puntos que influyen en relación a esto, por eso te invitamos a leer con detenimiento para entender si aplica a tu caso.<br/><br/> En Abstracta Academy, nos esforzamos por ofrecer cursos de alta calidad que satisfagan las necesidades y expectativas de nuestros estudiantes. Sin embargo, entendemos que pueden surgir circunstancias que requieran la solicitud de un reembolso.<br/> A continuación, detallamos nuestra política de reembolso, donde se exponen las situaciones y condiciones en las cuales se procede:
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">1. Condiciones de reembolso: </h6>
            - Los reembolsos se procesarán únicamente si el cliente tiene un avance en el curso menor al 30% del contenido.<br/>- Se considera que el cliente ha avanzado en el contenido del curso si ha accedido a plataforma y se observan registros de accesos en las lecciones que lo componen que corresponden al 30% del material (sea este de lectura, video y/o actividades evaluadas).<br/> - Los reembolsos no están disponibles para los clientes que registren un avance superior al 30% del contenido del curso.
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">2. Cuándo y cómo enviar la solicitud de reembolso: </h6>
             - La solicitud de reembolso debe realizarse dentro de los 3 días posteriores a la fecha de compra del curso.<br/>- Debe enviarse por escrito a <a href="mailto:hello@abstracta.academy" target="_blank">hello@abstracta.academy</a>, y describir el motivo de la solicitud y la información de contacto de la persona que realizó la compra.
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">3. Proceso de reembolso: </h6>
               - Una vez recibida la solicitud de reembolso y verificada la elegibilidad cuando cumpla con lo antes expuesto y que el motivo para ingresar la solicitud sea coherente y/o sea justificado, se procesará el reembolso dentro de un plazo de 5 días hábiles.<br/>- Los reembolsos se realizarán a través del mismo método de pago utilizado para la compra del curso, a menos que se acuerde lo contrario con el cliente.
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">4. Excepciones: </h6>
             - Nos reservamos el derecho de denegar solicitudes de reembolso en casos de abuso de la política de reembolso o comportamiento fraudulento.<br/>- Las solicitudes de reembolso fuera del período especificado o que no cumplan con los criterios mencionados anteriormente serán evaluadas según las circunstancias particulares del caso.
          <h6 style="margin-bottom: 1rem; margin-top: 2rem">5. Contacto: </h6>
              - Para obtener asistencia adicional, comunícate con nuestro equipo de soporte al cliente a través de <a href="mailto:hello@abstracta.academy" target="_blank">hello@abstracta.academy</a>.<br/><br/>Al realizar una compra de los servicios ofrecidos por Abstracta Academy, aceptas automáticamente los términos y condiciones de nuestra política de reembolso.
          `
        },
      ]
    };
  },
  computed: {
    filteredFaqsList() {
      if (this.searchValue.length < 2) return this.faqList;
      const ratings = this.faqList.map((faq) => { return [stringSimilarity.compareTwoStrings(this.searchValue.toLowerCase(), faq.question.toLowerCase()), faq] })
      const minVal = 0.03;
      const results = ratings.filter(([r, faq]) => { return r > minVal }).sort((a, b) => b[0] - a[0]).map(([r, faq]) => faq);
      return results;
    }
  },
  methods: {
  },
  mounted() {
    this.faqList = this.faqList.map((faq, index) => {
      return { ...faq, id: index }
    })
  }
};
